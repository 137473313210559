<template>
  <div class="head">
    <router-link to="" class="hd-logo">
      <img src="../assets/images/login_01.png" class="img" alt="" />
    </router-link>
    <div class="flex flex-align-center">
      <router-link to="" class="icon">
        <img src="../assets/images/icon_01.png" class="ico" alt="" />
        <i></i>
      </router-link>
      <router-link to="" class="icon">
        <img src="../assets/images/icon_02.png" class="ico" alt="" />
      </router-link>
      <el-dropdown @command="handleCommand">
        <div class="hd-user">
          <div class="font">
            <img :src="$store.getters.userImage||userInfo.headerPic" class="hdpic" alt="" />
            {{ userInfo.name }}<i class="el-icon-arrow-down el-icon--right"></i>
          </div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>修改密码</el-dropdown-item>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog append-to-body :visible.sync="forgetPop" width="530px">
      <div class="pop pop-psd">
        <div class="toptt">
          <span class="backbtn">修改密码</span>
          <i class="el-icon-close close" @click="forgetPop = false"></i>
          <div class="dix"></div>
        </div>
        <div class="detail">
          <el-form ref="form" :model="forgetForm" label-width="160px">
            <el-form-item label="请输入账号：">
              <el-input
                v-model="forgetForm.username"
                placeholder="11位手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="请输入原始密码：">
              <el-input
                type="password"
                v-model="forgetForm.password1"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="请输入新密码：">
              <el-input
                type="password"
                v-model="forgetForm.password2"
                placeholder="8位字母、数字组合"
              ></el-input>
            </el-form-item>
            <el-form-item label="再次输入新密码：">
              <el-input
                type="password"
                v-model="forgetForm.password3"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <div class="btngroup">
              <button class="ann confirm">确定</button>
              <button class="ann back" @click.prevent="forgetPop = false">
                返回
              </button>
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const fs = require("fs");
const mime = require("mime-types");
export default {
  data() {
    return {
      userInfo: {}, //用户信息
      userImages: "",
      forgetPop: false,
      forgetForm: {
        username: "",
        password1: "",
        password2: "",
        password3: "",
      },
    };
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("user"));
  },
  mounted() {},
  methods: {
    handleCommand(command) {
      console.log("12323123");
      if (command === "logout") {
        // 在这里执行退出登录操作
        this.quitLogin();
      } else {
        this.forgetPop = true;
      }
    },
    quitLogin() {
      this.$confirm("退出当前账户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sessionStorage.removeItem("user");
          sessionStorage.removeItem("userToken");
          this.$router.push("/login");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>

<style scoped>
.head {
  height: 70px;
  padding: 0 30px;
  border-bottom: 1px solid #2c3054;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  background: url(../assets/images/img_01.jpg) no-repeat center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hd-logo {
}

.hd-logo .img {
  height: 43px;
  width: auto;
}

.head .icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  position: relative;
}

.head .icon .ico {
  width: 50px;
  height: 50px;
}

.head .icon i {
  width: 6px;
  height: 6px;
  background-color: #ff0054;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -12px 0 0 3px;
}

.head .icon:hover {
  opacity: 0.85;
}

.hd-user {
  margin-left: 25px;
}

.hd-user .font {
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
}

.hd-user .hdpic {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #949494;
}
</style>
