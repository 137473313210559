<template>
  <div class="sidebar" :class="sidebar ? '' : 'hide'">
    <el-select
      v-model="projectId"
      placeholder="选择设备"
      @change="selectProject(projectId)"
    >
      <el-option
        v-for="item in projectList"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      />
    </el-select>
    <div class="scrollbox" v-if="sidebar">
      <el-menu
        router
        class="hd-menu"
        :default-active="$route.path"
        text-color="#fff"
        active-text-color="#ab4bfe"
      >
        <div v-for="(item, index) in menuData" :key="index">
          <el-menu-item
            v-if="item.children.length == 0"
            :index="item.url"
            class="el-title"
          >
            <!-- <img :src="item.iconUnCheck" class="ico ico-s" alt="" />
            <img :src="item.iconCheck" class="ico ico-h" alt="" /> -->
            <span slot="title" class="tit">{{ item.label }}</span>
          </el-menu-item>

          <el-submenu :index="item.id" v-if="item.children.length !== 0">
            <template slot="title">
              <!-- <i :class="item.iconClass"></i> -->
              <span class="tit">{{ item.label }}</span>
            </template>
            <el-menu-item-group
              v-for="(list, index) in item.children"
              :key="index"
            >
              <el-menu-item :index="list.url">
                <span slot="title" class="tit">{{ list.label }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </div>
      </el-menu>
    </div>
    <span class="menuicon" @click="menuicon"></span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sidebar: true,
      headcompany: "",
      activeIndex: "",
      menuData: [],
      projectId: "",
      projectName: "",
      projectList: [],
    };
  },
  components: {},
  watch: {
    $route() {
      this.setCurrentRoute();
    },
  },
  created() {
    this.menuData = JSON.parse(sessionStorage.getItem("user")).menuList;
    this.projectList = JSON.parse(sessionStorage.getItem("projectList"));
    this.projectId = Number(sessionStorage.getItem("projectId"));
    this.projectName = sessionStorage.getItem("projectName");
    this.setCurrentRoute();
  },
  mounted() {},

  methods: {
    menuicon() {
      this.sidebar = !this.sidebar;
    },
    setCurrentRoute() {
      this.activeIndex = this.$route.path; // 通过他就可以监听到当前路由状态并激活当前菜单
    },
    //选择项目
    selectProject(projectId) {
      var projectRoutes = [];
      sessionStorage.setItem("projectId", projectId);
      for (let i = 0; i < this.projectList.length; i++) {
        projectRoutes.push(this.projectList[i].domain);
        if (this.projectList[i].id === projectId) {
          this.projectName = this.projectList[i].name;
          sessionStorage.setItem("projectName", this.projectName);
        }
      }
      if (projectRoutes.indexOf(this.$route.path) >= 0) {
        this.$router.push("/projectIndex");
      } else {
        location.reload(true);
      }
    },
  },
};
</script>

<style scoped>
.sidebar {
  width: 260px;
  background-color: #111427;
  position: fixed;
  left: 0;
  top: 70px;
  bottom: 0;
  z-index: 100;
  transition: all 0.3s;
}

.sidebar .scrollbox {
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
}

.sidebar.hide {
  width: 0;
  padding: 0;
}

.sidebar.hide .menuicon {
  right: -20px;
  transform: rotate(180deg);
}

.sidebar .scrollbox::-webkit-scrollbar {
  display: none;
}

.sidebar >>> .el-submenu {
  margin-bottom: 15px;
}

.sidebar >>> .el-menu-item,
.sidebar >>> .el-submenu__title {
  height: 47px;
  line-height: 47px;
}

.sidebar >>> .el-menu {
  background-color: transparent;
  border: none !important;
}

.sidebar >>> .el-submenu__title {
  background: url(../assets/images/menu_01.png) no-repeat center;
  background-size: 100% 100%;
  position: relative;
  z-index: 100;
}

.el-title {
  background: url(../assets/images/menu_01.png) no-repeat center;
  background-size: 100% 100%;
  position: relative;
  z-index: 100;
  margin-bottom: 0.07813rem;
}

.el-title:hover {
  background-image: url(../assets/images/menu_01-1.png) !important;
}

.sidebar >>> .el-menu-item:focus,
.sidebar >>> .el-menu-item:hover {
  background: none;
}

.sidebar >>> .el-submenu__title:hover {
  background-image: url(../assets/images/menu_01-1.png);
}

.el-menu .ico {
  width: 35px;
  height: 35px;
  margin-right: 15px;
}

.el-menu .ico-h {
  display: none;
}

.sidebar >>> .el-submenu.is-opened .el-menu {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-top: none !important;
  border-bottom: none !important;
}

.sidebar >>> .el-submenu .el-menu-item:first-child::before {
  content: "";
  width: 1px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  left: -1px;
  top: -20px;
}

.sidebar >>> .el-submenu.is-opened .ico-h {
  display: inline-block;
}

.sidebar >>> .el-submenu.is-opened .ico-s {
  display: none;
}

.sidebar >>> .el-submenu:hover .ico-h {
  display: inline-block;
}

.sidebar >>> .el-submenu:hover .ico-s {
  display: none;
}

.sidebar >>> .el-submenu.is-opened .el-submenu__title {
  background-image: url(../assets/images/menu_01-1.png);
}

.el-submenu .el-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  line-height: 55px;
  position: relative;
  padding: 0 !important;
}

.el-submenu .el-menu-item::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: url(../assets/images/menu_02.png) no-repeat center;
  background-size: 100% 100%;
  left: 0;
  bottom: 0;
}

.sidebar >>> .el-submenu .el-menu-item:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar >>> .el-submenu .el-menu-item:last-child::after {
  display: none;
}

.sidebar >>> .el-submenu__icon-arrow {
  font-size: 14px;
  color: #fff;
}

.sidebar >>> .el-submenu .el-menu-item:hover,
.el-title:hover {
  color: #ab4bfe !important;
}

.sidebar >>> .el-submenu .el-submenu__title {
  transition: all 0s;
}

.sidebar >>> .el-submenu:hover .el-submenu__title,
.el-title:hover .el-submenu__title {
  color: #fff !important;
}

.sidebar >>> .el-submenu.is-active .el-submenu__title {
  color: #fff !important;
}

.sidebar >>> .el-submenu.is-opened .el-submenu__title {
  color: #fff !important;
}

.menuicon {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 97px;
  background: url(../assets/images/menu_03.png) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 45%;
  z-index: 100;
}

.menuicon.this {
  transform: scale(-1);
}

.sidebar >>> .el-input__inner {
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  border: none !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.26) !important;
  background: none !important;
}

.sidebar >>> .el-input__icon {
  line-height: 50px;
}
</style>
